import { React } from 'react';

// FILE
import Prestations from './Prestations.js';

// IMAGE
import imgLifestyle from '../../assets/imgPrestation/lifestyle.webp';
import imgProfessionnel from '../../assets/imgPrestation/professionnel.webp';
import imgMariage from '../../assets/imgPrestation/mariage.webp';
import imgAnimaux from '../../assets/imgPrestation/animaux.webp';

// CSS
import '../../assets/css/Prestation.css';


function Prestation() {
    return (
        <div className="container">
            <h2 id="myPrestations" className="row justify-content-center p-5 fs-secondary">Mes prestations</h2>
            <div className="container">
                <div className="row d-flex justify-content-center">
                    <Prestations link="./Mariage" title="Mariage" img={imgMariage} />
                </div>
                <div className="row d-flex justify-content-center">
                    <Prestations link="./Professionnel" title="Professionnel" img={imgProfessionnel} />
                </div>
                <div className="row d-flex justify-content-center">
                    <Prestations link="./Lifestyle" title="Lifestyle" img={imgLifestyle} />
                </div>
                <div className="row d-flex justify-content-center">
                    <Prestations link="./Animaux" title="Animaux" img={imgAnimaux} />
                </div>
            </div>
        </div>
    )
};

export default Prestation;