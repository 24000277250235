import { React, useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';

// Components
import Header from './components/header/Header';
import Footer from './components/footer/Footer';

// Pages
import Home from './pages/Home';
import WhoIam from './pages/WhoIam'; 
import Lifestyle from './pages/Lifestyle';
import Professionnel from './pages/Professionnel';
import Mariage from './pages/Mariage';
import Animaux from './pages/Animaux';

// CSS
import "./assets/css/mediaQueries.css";

// GOOGLE ANALYTICS
import TagManager from 'react-gtm-module';

// Scroll on Top for each page
function ScrollToTop() {
  const { pathname } = useLocation()

  useEffect(() => {
    const timer = setTimeout(() => {
      window.scrollTo(0, 0);
    }, 300);
    
    return () => clearTimeout(timer);
    }, [pathname]);

  return null
}

function App() {
  TagManager.initialize({
    gtmId: 'GTM-WPF5BJXF' 
  });
  return (
    <div>
      <ScrollToTop />
      <Header />

{/* ETABLISSEMENT DES ROUTES */}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/qui-suis-je" element={<WhoIam />} />
        <Route path="/lifestyle" element={<Lifestyle />} />
        <Route path="/professionnel" element={<Professionnel />} />
        <Route path="/mariage" element={<Mariage />} />
        <Route path="/animaux" element={<Animaux />} />
      </Routes>

      <Footer />
    </div>
  );
}

export default App;
