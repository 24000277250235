import React from 'react'; 
import { Link } from 'react-router-dom';

function Prestation({link, title, img}) {
    return (
        <Link to={link} className="position-relative d-flex justify-content-center align-items-center img-clickable w-50 p-0 m-3">
            <img className="w-100" src={img} alt={`prestation ${title}`} loading="lazy"></img>
            <div className="position-absolute backgroundContact transformBackgroundPrestation p-2">
                <p className="m-0 fw-bold text-black">{title}</p>
            </div>
        </Link>
    )
}

export default Prestation;