import React from 'react';
import { useInView } from 'react-intersection-observer';
import '../assets/css/Description.css';

function Description() {
    // Observer <h2>
    const { ref : refTitle, inView : inViewTitle } = useInView({
        threshold: 0.1,
        triggerOnce : true,
    })

    // Observer <p>
    const { ref : refContent, inView : inViewContent } = useInView({
        threshold: 0.1,
        triggerOnce : true,
    })

    return (
        <div  className="d-flex flex-column align-items-center m-2 m-sm-5 p-sm-5 p-4 pt-sm-2"> 
                <h2 ref={refTitle} className={`m-sm-3 mb-sm-5 mb-4 p-sm-2 opacity-0 fs-primary ${inViewTitle ? "slideInTitle opacity-100" : ""}`}>Revivre vos souvenirs grâce à vos photos</h2>
                <p ref={refContent} className={`m-sm-5 ps-sm-5 fs-6 ms-sm-2 mt-1 mb-1 lh-sm opacity-0 ${inViewContent ? "slideInContent opacity-100" : ""}`}>
                    <p>Soyez les bienvenus !</p>
                    <p>Moi c'est Marie, et je dois vous faire par de quelque chose me concernant : j'ai <strong>peur d'oublier</strong>.</p>
                    <p>Alors, pour faire face à cette peur, je fais des <strong>photos</strong> de tout mes <strong>moments de vie</strong>, afin de pouvoir tout <strong>garder précieusement en mémoire</strong>.</p>
                        <p>C'est ma conviction : on peut, grâce à la photographie, <strong>revivre nos souvenirs</strong> encore et encore.</p>
                        <p>Alors, qui que vous vous soyez, <strong>particuliers</strong>, <strong>entreprises</strong>, <strong>futurs mariés</strong>, <strong>propriétaires 
                        de compagnons à quattre pattes</strong>...</p>
                        <p>Si vous partagez cette conviction... Vous êtes au bon endroit !</p>
                        <p>Au plaisir d'échanger avec vous.</p>
                        <h4 className="d-flex justify-content-end m-3 m-sm-0">Marie Populaire Photographie</h4>
                </p>
        </div>
    )
}

export default Description;