import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { isMobileOnly } from 'react-device-detect';

// IMG
import Logo from '../../assets/imgHeader/logo-header.svg'; 
import Burger from '../../assets/imgHeader/icon-burger.svg';

// CSS
import './Header.css';


function Header() {
    // TOGGLE MENU
    const [isOpen, setIsOpen] = useState(false);
    const toggleMenu = () => setIsOpen(!isOpen);

    // NAVIGUE vers HOME puis SCROLL TO PRESTATION
    const { pathname } = useLocation();
    const navigate = useNavigate();

    const scrollToPrestations = () => {
        if (pathname === "/") {
            const prestationSection = document.getElementById('myPrestations');
            prestationSection.scrollIntoView({ behavior: 'smooth', block: 'start' });
        } else if (pathname !== '/') {
            navigate('/');
            setTimeout(() => {
                const prestationSection = document.getElementById('myPrestations');
                prestationSection.scrollIntoView({ behavior: 'smooth', block: 'start' });
            },500);
        }
    }

    // SCROLL TO DIV 'footer' for contactForm
    const scrollToContact = () => {
        setTimeout(() => {
        const prestationSection = document.getElementById('footerForm');
        prestationSection.scrollIntoView({ behavior: 'smooth', block: 'start' })
        }, 0);
    }


    return (
    <header>
        <div className="container">
            <div className="row">
                <div className="col"></div>
                {/* LOGO */}
                <div className="col d-flex justify-content-center m-1 z-2">
                    <Link className="m-2 rounded-circle" to="/" onClick={isMobileOnly ? toggleMenu : null}> {/* Détecte l'accès au site pour mobile */}
                        <img className="rounded-circle header__logo--size" src={Logo} alt="logo marie populaire photographie"></img>
                    </Link>
                </div>
                {/* ICON MENU BURGER FOR MOBLE */}
                <div className="col d-flex align-items-center">
                        <div className="col d-flex justify-content-center">
                            <button className="d-block d-sm-none" onClick={toggleMenu}>
                                <img src={Burger} alt="icon menu burger"></img>
                            </button>
                        </div>
                </div>
            </div>
            {/* MENU FOR ALL DEVICES */}
            <div className="row d-none d-sm-block">
                <nav className="col header__menu--size position-fixed rounded-end p-2 z-2 d-flex flex-column">
                    <ul className="fs-6 list-unstyled d-flex justify-content-around flex-column h-100 m-0">
                        <li><Link className="ps-1 text-decoration-none fw-bold header__link--effect" to="/">Accueil</Link></li>
                        <li><Link className="ps-1 text-decoration-none fw-bold header__link--effect" to="/qui-suis-je">Qui suis-je ?</Link></li>
                        <li className="ps-1"><button className="header__link--effect p-0 fw-bold" onClick={scrollToPrestations}>Mes prestations</button></li>
                        <li className="ps-4"><Link className="header__link--effect" to="/mariage">Mariage</Link></li>
                        <li className="ps-4"><Link className="header__link--effect" to="/professionnel">Professionnel</Link></li>
                        <li className="ps-4"><Link className="header__link--effect" to="/lifestyle">Lifestyle</Link></li>
                        <li className="ps-4"><Link className="header__link--effect" to="/animaux">Animaux</Link></li>                    
                        <li className="ps-1 fw-bold"><button className="header__link--effect fw-bold" onClick={scrollToContact}>Contact</button></li>
                    </ul>
                </nav>
            </div>
        </div>
        {/* MENU BURGER FOR MOBILE */}
        <div className="row">
            {isOpen && (
                <nav className="col header__toggle--size ps-4 pb-2 pt-2">
                    <ul className="list-unstyled d-flex flex-column justify-content-around m-0">
                        <li><Link className="header__link--effect fw-bold" to="/" onClick={toggleMenu}>Accueil</Link></li>
                        <li><Link className="header__link--effect fw-bold" to="/qui-suis-je" onClick={toggleMenu}>Qui suis-je ?</Link></li>
                        <li><button className="header__link--effect fw-bold" onClick={scrollToPrestations}>Mes prestations</button></li>
                        <ul className="list-unstyled ps-4">
                            <li><Link className="header__link--effect" to="/mariage" onClick={toggleMenu}>Mariage</Link></li>
                            <li><Link className="header__link--effect" to="/professionnel" onClick={toggleMenu}>Professionnel</Link></li>
                            <li><Link className="header__link--effect" to="/lifestyle" onClick={toggleMenu}>Lifestyle</Link></li>
                            <li><Link className="header__link--effect" to="/animaux" onClick={toggleMenu}>Animaux</Link></li>
                        </ul>
                        <li><button className="header__link--effect fw-bold" onClick={() => {toggleMenu(); scrollToContact()}}>Contact</button></li>
                    </ul>
                </nav>
            )}
        </div>
    </header>
    );
}

export default Header;